<template>
  <article id="wrap">
    <component :is="'style'">
      header.head_photo::before {
      background: {{ background }};
      background-size: contain;
      }
      header.head_photo {}
    </component>
    <header class="head_photo">
      <ul class="page_head head_gray">
        <li class="menuL"><router-link :to="{name:'artist'}">
          <img src="@/assets/img/icon_back.png" alt="戻るボタン">
        </router-link></li>
        <li class="menuC"></li>
        <li class="menuR"></li>
      </ul>
    </header>
<!-- 
    <AppHeader theme="white">
      <div style="color: #262626;">マスターを選択</div>
    </AppHeader> -->


    <section class="contents">

      <div class="contentInner mb_100"  v-if="artist">
        <h2>{{ artist.name }}</h2>
        <div style="padding: 20px;">{{ artist.detail }}</div>

        <TalkRoomBoard v-for="payment_plan in artist.talk_room.payment_plans" :key="`payment_plan-${payment_plan.id}`"
                       :talk_room="artist.talk_room" :payment_plan="payment_plan"></TalkRoomBoard>

      </div><!--.contentInner-->

    </section><!--.contents-->

    <AppFooter fixed style="display: none;"></AppFooter>

  </article>
</template>

<script>
import AppFooter from '@/components/AppFooter';
import TalkRoomBoard from '@/components/TalkRoomBoard';
export default {
  name: 'ArtistDetail',
  components: { TalkRoomBoard, AppFooter },
  data () {
    return {
      artist: null
    }
  },
  computed: {
    image () {
      if (!this.artist || !this.artist.image) return false;
      return `${process.env.VUE_APP_API_ROOT}../${this.artist.image}`;
    },
    background () {
      return `url(${this.image}) no-repeat top center`
    },
    inputCssVars () {
      return {
        '--before-background': this.background
      }
    }
  },
  created () {
    this.initialize();
  },
  methods: {
    async initialize () {
      const response = await this.$http.get(`artists/${this.$route.params.artist}`);
      this.artist = response.data;
    }
  }
}
</script>

<style scoped>
.contents {
      position: absolute;
      z-index: 1000000;
}
.contentInner {
  margin-top: 200px;
  background-color: white;
}
@media screen and (min-width: 420px){
  .contentInner {
    margin-top: 300px;
  }
}
@media screen and (min-width: 768px){
  .contentInner {
    margin-top: 400px;
  }
}
</style>
